import { BackButton } from 'common-front/src/components/buttons/backButton';
import { Empty } from 'common-front/src/components/empty/empty';
import { TabViewResponsive } from 'common-front/src/components/tabViewsReponsive/tabViewResponsive';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserAccreditationsWished } from 'common-front/src/users/information/userAccreditationsWished';
import { UserPositionsWished } from 'common-front/src/users/information/userPositionsWished';
import { UserPositionsWishedSlots } from 'common-front/src/users/information/userPositionsWishedSlots';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditationState, VolunteerRegistrationState } from 'common/src/generated/types';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useMemberRegistrationQuery } from '../../../generated/graphqlHooks';
import { SpaceContainer } from '../../common/spaceContainer';
import { MemberRegistrationAccreditations } from './accreditations/show/memberRegistrationAccreditations';
import { MemberRegistrationAssignments } from './assignments/show/memberRegistrationAssignments';
import { MemberRegistrationUpdateAvailability } from './availability/update/memberRegistrationUpdateAvailability';
import { MemberRegistrationDocuments } from './documents/show/memberRegistrationDocuments';
import { MemberRegistrationInformation } from './information/show/memberRegistrationInformation';
import { MemberRegistrationUpdateInformation } from './information/update/memberRegistrationUpdateInformation';
import { MemberRegistrationUpdateWishedAccreditations } from './wishedAccreditations/update/memberRegistrationUpdateWishedAccreditations';
import { MemberRegistrationUpdateWishedPositions } from './wishedPositions/update/memberRegistrationUpdateWishedPositions';

export const MemberSpace = () => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const { data, loader, reload } = useMemberRegistrationQuery({
        organizationId,
        eventId,
        userInfoId,
        isEvent: !!eventId
    });
    const isEditAccreditationsDisabled = loader
        ? undefined
        : data.organization?.userInfo.volunteerRegistration?.accreditationState ===
          AccreditationState.Accredited;

    const isEditPositionsDisabled = loader
        ? undefined
        : data.organization?.userInfo.volunteerRegistration?.state ===
          VolunteerRegistrationState.Assigned;

    const userInfo = React.useMemo(() => {
        if (loader) {
            return null;
        } else {
            const userInfo = data.organization?.userInfo;
            const vr = userInfo?.volunteerRegistration;

            return eventId
                ? {
                      ...userInfo,
                      volunteerRegistration: {
                          ...vr!,
                          accreditationsUsersInfos: data.organization
                              .hideAccreditationsUntilConfirmationEmailSent
                              ? vr!.accreditationsUsersInfos.filter(
                                    (aui) => aui.isConfirmationEmailSent
                                )
                              : vr!.accreditationsUsersInfos,
                          positionsSlotsUsersInfos: data.organization
                              .hideAssignmentsUntilConfirmationEmailSent
                              ? vr!.positionsSlotsUsersInfos.filter(
                                    (psui) => psui.isConfirmationEmailSent
                                )
                              : vr!.positionsSlotsUsersInfos
                      }
                  }
                : data.organization.userInfo;
        }
    }, [data, loader]);

    return (
        loader ||
        (userInfo ? (
            <SpaceContainer
                isLoading={false}
                organization={data.organization}
                spaceDesign={{
                    themeColors: data.organization?.membersSpaceDesign?.colors,
                    bannerSrc: data.organization?.membersSpaceDesign?.banner?.url,
                    logoSrc: data.organization?.membersSpaceDesign?.logo?.url
                }}
                userInfo={userInfo}
            >
                <Switch>
                    <Route
                        exact
                        path={MembersPaths.MEMBER_REGISTRATION({
                            organizationId: ':organizationId',
                            userInfoId: ':userInfoId',
                            eventId: eventId ? ':eventId' : null
                        })}
                    >
                        <Empty
                            path={MembersPaths.MEMBER_REGISTRATION_INFORMATION(
                                organizationId,
                                userInfoId,
                                eventId
                            )}
                            replace={true}
                        />
                    </Route>

                    <Route
                        path={MembersPaths.MEMBER_REGISTRATION_INFORMATION_EDIT(
                            ':organizationId',
                            ':userInfoId',
                            eventId ? ':eventId' : null
                        )}
                    >
                        <MemberRegistrationUpdateInformation
                            country={
                                data.organization?.event?.country ||
                                data.organization?.country ||
                                undefined
                            }
                            customFields={data.organization.customFields.nodes}
                            reload={reload}
                            userInfo={userInfo}
                        />
                    </Route>

                    <Route
                        path={MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT(
                            ':organizationId',
                            ':userInfoId',
                            ':eventId'
                        )}
                    >
                        {!isEditPositionsDisabled && (
                            <MemberRegistrationUpdateAvailability reload={reload} />
                        )}
                    </Route>

                    <Route
                        path={MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT(
                            ':organizationId',
                            ':userInfoId',
                            ':eventId'
                        )}
                    >
                        {!isEditPositionsDisabled && (
                            <MemberRegistrationUpdateWishedPositions reload={reload} />
                        )}
                    </Route>

                    <Route
                        path={MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT(
                            ':organizationId',
                            ':userInfoId',
                            ':eventId'
                        )}
                    >
                        {!isEditAccreditationsDisabled && (
                            <MemberRegistrationUpdateWishedAccreditations reload={reload} />
                        )}
                    </Route>

                    <Route>
                        <Flex direction="column">
                            <Flex direction="column" gap="2">
                                <BackButton
                                    color="primary"
                                    returnPathFallback={MembersPaths.MEMBER(
                                        organizationId,
                                        userInfoId
                                    )}
                                >
                                    {translate('retour_l_accu_00810')}
                                </BackButton>

                                <Box font="gray900 displayXs semiBold">
                                    {eventId
                                        ? data.organization.event?.name
                                        : data.organization.name}
                                </Box>
                            </Flex>

                            <TabViewResponsive.Root>
                                <TabViewResponsive.Section
                                    getPath={(isRouteComponent: boolean = false) =>
                                        MembersPaths.MEMBER_REGISTRATION_INFORMATION(
                                            isRouteComponent ? ':organizationId' : organizationId,
                                            isRouteComponent ? ':userInfoId' : userInfoId,
                                            isRouteComponent
                                                ? eventId
                                                    ? ':eventId'
                                                    : undefined
                                                : eventId
                                        )
                                    }
                                    title={translate('informations_pe_37892')}
                                >
                                    <MemberRegistrationInformation userInfo={userInfo} />
                                </TabViewResponsive.Section>

                                {eventId && (
                                    <TabViewResponsive.Section
                                        getPath={(isRouteComponent: boolean = false) =>
                                            MembersPaths.MEMBER_REGISTRATION_AVAILABILITY(
                                                isRouteComponent
                                                    ? ':organizationId'
                                                    : organizationId,
                                                isRouteComponent ? ':userInfoId' : userInfoId,
                                                isRouteComponent ? ':eventId' : eventId
                                            )
                                        }
                                        title={translate('disponibilit_s_49923')}
                                    >
                                        <UserPositionsWishedSlots
                                            editFormPath={
                                                MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT_FORM
                                            }
                                            editPath={MembersPaths.MEMBER_REGISTRATION_AVAILABILITY_EDIT(
                                                organizationId,
                                                userInfoId,
                                                eventId
                                            )}
                                            eventId={eventId}
                                            formsUsersInfos={userInfo.formsUsersInfos}
                                            isEditDisabled={isEditPositionsDisabled}
                                            organizationId={organizationId}
                                            showEditButton={true}
                                            userInfoId={userInfoId}
                                        />
                                    </TabViewResponsive.Section>
                                )}

                                {eventId && (
                                    <TabViewResponsive.Section
                                        getPath={(isRouteComponent: boolean = false) =>
                                            MembersPaths.MEMBER_REGISTRATION_POSITIONS(
                                                isRouteComponent
                                                    ? ':organizationId'
                                                    : organizationId,
                                                isRouteComponent ? ':userInfoId' : userInfoId,
                                                isRouteComponent ? ':eventId' : eventId
                                            )
                                        }
                                        title={translate('missions_souhai_23225')}
                                    >
                                        <UserPositionsWished
                                            editFormPath={
                                                MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT_FORM
                                            }
                                            editPath={MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT(
                                                organizationId,
                                                userInfoId,
                                                eventId
                                            )}
                                            formsUsersInfos={userInfo.formsUsersInfos}
                                            isEditDisabled={isEditPositionsDisabled}
                                        />
                                    </TabViewResponsive.Section>
                                )}

                                {eventId && (
                                    <TabViewResponsive.Section
                                        getPath={(isRouteComponent: boolean = false) =>
                                            MembersPaths.MEMBER_REGISTRATION_ASSIGNMENTS(
                                                isRouteComponent
                                                    ? ':organizationId'
                                                    : organizationId,
                                                isRouteComponent ? ':userInfoId' : userInfoId,
                                                isRouteComponent ? ':eventId' : eventId
                                            )
                                        }
                                        title={translate('affectation_plural')}
                                    >
                                        <MemberRegistrationAssignments userInfo={userInfo} />
                                    </TabViewResponsive.Section>
                                )}

                                {eventId && (
                                    <TabViewResponsive.Section
                                        getPath={(isRouteComponent: boolean = false) =>
                                            MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS(
                                                isRouteComponent
                                                    ? ':organizationId'
                                                    : organizationId,
                                                isRouteComponent ? ':userInfoId' : userInfoId,
                                                isRouteComponent ? ':eventId' : eventId
                                            )
                                        }
                                        title={translate('desired_accreditations')}
                                    >
                                        <UserAccreditationsWished
                                            editFormPath={
                                                MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT_FORM
                                            }
                                            editPath={MembersPaths.MEMBER_REGISTRATION_WISHED_ACCREDITATIONS_EDIT(
                                                organizationId,
                                                userInfoId,
                                                eventId
                                            )}
                                            formsUsersInfos={userInfo.formsUsersInfos}
                                            isEditDisabled={isEditAccreditationsDisabled}
                                        />
                                    </TabViewResponsive.Section>
                                )}

                                {eventId && (
                                    <TabViewResponsive.Section
                                        getPath={(isRouteComponent: boolean = false) =>
                                            MembersPaths.MEMBER_REGISTRATION_ACCREDITATIONS(
                                                isRouteComponent
                                                    ? ':organizationId'
                                                    : organizationId,
                                                isRouteComponent ? ':userInfoId' : userInfoId,
                                                isRouteComponent ? ':eventId' : eventId
                                            )
                                        }
                                        title={translate('accr_ditations_39450')}
                                    >
                                        <MemberRegistrationAccreditations userInfo={userInfo} />
                                    </TabViewResponsive.Section>
                                )}

                                {eventId && (
                                    <TabViewResponsive.Section
                                        getPath={(isRouteComponent: boolean = false) =>
                                            MembersPaths.MEMBER_REGISTRATION_DOCUMENTS(
                                                isRouteComponent
                                                    ? ':organizationId'
                                                    : organizationId,
                                                isRouteComponent ? ':userInfoId' : userInfoId,
                                                isRouteComponent ? ':eventId' : eventId
                                            )
                                        }
                                        title={translate('documents_87028')}
                                    >
                                        <MemberRegistrationDocuments
                                            campaigns={userInfo.campaigns.nodes}
                                        />
                                    </TabViewResponsive.Section>
                                )}
                            </TabViewResponsive.Root>
                        </Flex>
                    </Route>
                </Switch>
            </SpaceContainer>
        ) : (
            // This path should be unreachable
            <Box>ERROR</Box>
        ))
    );
};
