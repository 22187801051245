import { Separator } from 'common-front/src/designSystem/components/separator';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CustomFieldWithConditionFragment,
    DelegationSpaceDashboardQuery
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { FieldService } from 'common/src/vo/field';
import * as React from 'react';

interface IDelegationCustomFieldsProps {
    customFields: CustomFieldWithConditionFragment[];
    delegation: DelegationSpaceDashboardQuery['organization']['delegation'];
}

export const DelegationCustomFields = (props: IDelegationCustomFieldsProps) => {
    const fieldService = useService(FieldService);

    return (
        <Flex direction="column" gap="4" width={1}>
            {props.customFields.map((cf, index) => {
                const value = fieldService.getValueString(cf, props.delegation.fields, {
                    fileReturnValue: 'url'
                });

                return (
                    <React.Fragment key={cf.id}>
                        {index !== 0 && <Separator direction="horizontal" />}

                        <Flex align="center" gap="3" justify="between">
                            <Box font="gray800 textSm medium">{cf.name}</Box>

                            <Box>{isNonEmptyString(value) ? value : '-'}</Box>
                        </Flex>
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};
