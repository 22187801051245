import { Empty } from 'common-front/src/components/empty/empty';
import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { FormMissions } from 'common-front/src/forms/missions/formMissions';
import {
    useUserInfoPositionsUpdateMutation,
    useUserUpdateMissionsInfosQuery
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { IUserUpdateMissionsValues } from 'common-front/src/users/updateMissions/userUpdateMissionsValues';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    RegisterPositionDisplay,
    RegisterPositionFilter,
    RegisterSlotDisplay,
    UserUpdateMissionsInfosQuery
} from 'common/src/generated/types';
import { VolunteerRegistrationInputService } from 'common/src/input/volunteerRegistrationInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { MembersPaths } from 'common/src/util/membersPaths';
import { FormIdPathParam } from 'common/src/util/pathsTypes';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { UpdateForm } from '../../../../common/updateForm';

interface IMemberRegistrationUpdateWishedPositionsProps {
    reload(): void;
}

export const MemberRegistrationUpdateWishedPositions = ({
    reload
}: IMemberRegistrationUpdateWishedPositionsProps) => {
    const {
        translate,
        params: { eventId, organizationId, userInfoId }
    } = useHeavent();
    const validateService = useService(ValidateService);
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);

    const { data, loader: queryLoader } = useUserUpdateMissionsInfosQuery({
        organizationId,
        eventId,
        userInfoId
    });
    const { mutate, loader: mutationLoader } = useUserInfoPositionsUpdateMutation();

    const forms = React.useMemo(
        () =>
            (data.organization?.userInfo?.formsUsersInfos ?? []).flatMap((fui) => {
                if (fui.form.positionDisplay !== RegisterPositionDisplay.None) {
                    return [fui.form];
                } else {
                    return [];
                }
            }),
        [data?.organization?.userInfo?.formsUsersInfos]
    );
    const editableForms = React.useMemo(
        () => forms.filter((form) => form.isEditableInMemberSpace),
        [forms]
    );
    const initialSelectedMissions = React.useMemo(
        () =>
            forms.map((form) => {
                const formUserInfo = data.organization.userInfo.formsUsersInfos.find(
                    (fui) => fui.formId === form.id
                );

                return {
                    formId: form.id,
                    ...volunteerRegistrationInput.volunteersRegistrationsWishedPositionsUpdateInputDefault(
                        formUserInfo
                    )
                };
            }),
        [forms, data?.organization?.userInfo]
    );

    return (
        queryLoader || (
            <Switch>
                <Route
                    path={MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT_FORM(
                        ':organizationId',
                        ':userInfoId',
                        ':eventId',
                        ':formId'
                    )}
                >
                    <UpdateForm
                        child={(
                            currForm: UserUpdateMissionsInfosQuery['event']['forms'][number],
                            index: number
                        ) => (
                            <Form
                                direction="column"
                                height={1}
                                initialValues={{
                                    userInfo: { missions: initialSelectedMissions }
                                }}
                                render={({ form, handleSubmit, values }) => (
                                    <Flex direction="column" gap="5">
                                        <Box>
                                            <FormMissions
                                                change={form.change}
                                                event={data.event}
                                                options={{
                                                    ...currForm,
                                                    showFullPosition: true,
                                                    slotDisplay: RegisterSlotDisplay.Hide,
                                                    positionFilter: RegisterPositionFilter.None
                                                }}
                                                positionsCategoriesIds={
                                                    values.userInfo.missions[index]
                                                        .positionsCategoriesIds
                                                }
                                                positionsIds={
                                                    values.userInfo.missions[index].positionsIds
                                                }
                                                positionsSlotsIds={
                                                    values.userInfo.missions[index]
                                                        .positionsSlotsIds
                                                }
                                                prefix={`userInfo.missions[${index}].`}
                                                slots={[]}
                                                userInfoFields={data.organization.userInfo.fields}
                                            />
                                        </Box>

                                        <Separator direction="horizontal" />

                                        <Button
                                            color="primary"
                                            textAlign="center"
                                            onClick={handleSubmit}
                                        >
                                            {mutationLoader || translate('update')}
                                        </Button>
                                    </Flex>
                                )}
                                validate={validateService.validateForForm(
                                    volunteerRegistrationInput.userInfoWishedPositionsSchema(
                                        currForm.positionRanking,
                                        currForm.positionDisplay
                                    )
                                )}
                                width={1}
                                onSubmit={async (values: IUserUpdateMissionsValues) => {
                                    await mutate({
                                        eventId,
                                        userId: data.organization.userInfo.userId,
                                        userInfoId,
                                        userInfoPositions: values.userInfo.missions
                                    });

                                    reload();
                                }}
                            />
                        )}
                        forms={editableForms}
                        getEditPath={(formId) =>
                            MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT_FORM(
                                organizationId,
                                userInfoId,
                                eventId,
                                formId
                            )
                        }
                        returnPathFallback={MembersPaths.MEMBER_REGISTRATION_POSITIONS(
                            organizationId,
                            userInfoId,
                            eventId
                        )}
                        title={translate('update_desired_tasks')}
                        userInfo={data.organization.userInfo}
                    />
                </Route>

                <Route>
                    <Empty
                        path={MembersPaths.MEMBER_REGISTRATION_POSITIONS_EDIT_FORM(
                            organizationId,
                            userInfoId,
                            eventId,
                            isNonEmptyArray(editableForms)
                                ? editableForms[0].id
                                : (-1 as FormIdPathParam)
                        )}
                        replace={true}
                    />
                </Route>
            </Switch>
        )
    );
};
