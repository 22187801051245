import { Form } from 'common-front/src/components/form/form';
import { executeQuery } from 'common-front/src/components/graphql/graphql';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import {
    Mutations,
    useLoginCodeEmailCreateMutation
} from 'common-front/src/generated/graphqlHooks';
import * as Cognito from 'common-front/src/util/aws/cognito';
import {
    initiateAuthWithSignUp,
    reportAuthError,
    sendCustomChallengeAnswer
} from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CSS } from 'common/src/designSystem/components/stitches';
import { LoginCodeSource, OrganizationId } from 'common/src/generated/types';
import { AuthInputService } from 'common/src/input/authInput';
import { ISignInCodeValues } from 'common/src/input/signInCodeInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { MembersPaths } from 'common/src/util/membersPaths';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { noop } from 'lodash-es';
import { Duration } from 'luxon';
import * as React from 'react';

interface IAuthFormProps {
    css?: CSS;
    organizationId: OrganizationId;
}

export const AuthForm = (props: IAuthFormProps) => {
    const translate = useTranslate();
    const validateService = useService(ValidateService);
    const authInput = useService(AuthInputService);
    const { mutate: loginCodeEmailCreate } = useLoginCodeEmailCreateMutation();
    const [step, setStep] = React.useState(0);
    const [currentInitiateAuthResult, setInitiateAuthResult] =
        React.useState<Cognito.IInitiateAuthResult | null>(null);
    const isDelegations = location.pathname.includes('/delegations');
    const onSuccess = React.useCallback(
        async (token: string) => {
            await executeQuery(Mutations.UserCreate, { token });

            if (isDelegations) {
                location.href = CommonPaths.SHOW.LIST(
                    DelegationsPaths.DELEGATIONS({
                        organizationId: props.organizationId
                    })
                );
            } else {
                location.href = MembersPaths.PROFILES(props.organizationId);
            }
        },
        [isDelegations]
    );
    const [currentAttempt, setCurrentAttempt] = React.useState(0);
    const [isRestartVisible, setIsRestartVisible] = React.useState(false);
    const [challengeStartTime, setChallengeStartTime] = React.useState(Date.now());
    const restart = () => {
        location.reload();
    };

    return (
        <Form
            css={props.css}
            direction="column"
            initialValues={{ email: '', code: '' }}
            validate={validateService.validateForForm(authInput.signInSchema())}
            onSubmit={async (values: ISignInCodeValues) => {
                if (step === 0) {
                    await loginCodeEmailCreate({
                        loginCodeEmail: {
                            email: values.email,
                            organizationId: props.organizationId,
                            source: isDelegations
                                ? LoginCodeSource.DelegationsSpace
                                : LoginCodeSource.MembersSpace
                        }
                    });

                    setChallengeStartTime(Date.now());
                    const initiateAuthResult = await initiateAuthWithSignUp(
                        values.email,
                        noop,
                        noop
                    );

                    setInitiateAuthResult(initiateAuthResult);
                    setStep(1);
                } else {
                    const { user, callbacks } = currentInitiateAuthResult!;

                    return sendCustomChallengeAnswer({
                        code: values.code,
                        user,
                        callbacks
                    }).then(onSuccess, (error) => {
                        if (error.type === 'RETRY') {
                            setCurrentAttempt(currentAttempt + 1);

                            return {
                                code: translate('le_code_n_est_p_95065', 3 - (currentAttempt + 1))
                            };
                        } else {
                            reportAuthError(error.message, values.email, challengeStartTime);
                            setIsRestartVisible(true);

                            let FORM_ERROR;
                            if (
                                Duration.fromMillis(Date.now() - challengeStartTime).as('minutes') >
                                3
                            ) {
                                FORM_ERROR = translate('le_code_a_expir_45975');
                            } else {
                                FORM_ERROR = translate('le_code_n_est_p_12317');
                            }

                            return {
                                FORM_ERROR
                            };
                        }
                    });
                }
            }}
            render={({ handleSubmit, submitting }) => (
                <>
                    <Box
                        color="gray900"
                        fontSize={{ '@initial': 'textXl', '@tablet': 'displaySm' }}
                        fontWeight="semiBold"
                        textAlign={{ '@initial': 'center', '@desktop': 'start' }}
                    >
                        {isDelegations
                            ? translate('connexion_l_e_31965')
                            : translate('connexion_mon_39916')}
                    </Box>

                    <Spacer height="3" />

                    <Box color="gray500" textAlign={{ '@initial': 'center', '@desktop': 'start' }}>
                        {isDelegations
                            ? translate('connectez_vous_37685')
                            : translate('connecter_vous_19749')}
                    </Box>

                    <Spacer height="7" />

                    <TextInput
                        autoComplete="email"
                        label={translate('adresse_e_mail_60930')}
                        placeholder={translate('entrez_votre_ad_42451')}
                        name="email"
                        isEmail={true}
                        state={step === 1 || isRestartVisible ? 'disabled' : 'active'}
                    />

                    {step === 1 && (
                        <>
                            <Spacer height="4" />

                            <TextInput
                                autoComplete="one-time-code"
                                label={translate('code_re_u_par_e_75150')}
                                placeholder={translate('code_re_u_par_e_75150')}
                                name="code"
                                state={isRestartVisible ? 'disabled' : undefined}
                                type="number"
                            />
                        </>
                    )}

                    <Spacer height="6" />

                    {isRestartVisible ? (
                        <Button textAlign="center" onClick={restart}>
                            {translate('recommencer_14712')}
                        </Button>
                    ) : (
                        <Button textAlign="center" isLoading={submitting} onClick={handleSubmit}>
                            {step === 0
                                ? translate('recevoir_un_cod_82139')
                                : translate('se_connecter_63820')}
                        </Button>
                    )}
                </>
            )}
        />
    );
};
