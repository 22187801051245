import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MembersPaths } from 'common/src/util/membersPaths';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useMemberQuery } from '../../generated/graphqlHooks';
import { SpaceContainer } from '../common/spaceContainer';
import { MemberAssignmentSkeleton } from './assignments/memberAssignment';
import { MemberAssignments } from './assignments/memberAssignments';
import { UserInfoAssignments } from './assignments/userInfoAssignments';
import { MemberCampaigns, MemberCampaignsSkeleton } from './campaigns/memberCampaigns';
import { MemberDocuments, MemberDocumentsSkeleton } from './documents/memberDocuments';
import { MemberHeader } from './memberHeader';

export const Member = () => {
    const {
        history,
        translate,
        params: { organizationId, userInfoId }
    } = useHeavent();
    const { data, isLoading, loader } = useMemberQuery({ organizationId, userInfoId });
    const registrations = React.useMemo(() => {
        const filteredRegistrations = (data.userInfo?.volunteersRegistrations ?? []).map((vr) => ({
            ...vr,
            accreditationsUsersInfos: data.organization
                ?.hideAccreditationsUntilConfirmationEmailSent
                ? vr.accreditationsUsersInfos.filter((aui) => aui.isConfirmationEmailSent)
                : vr.accreditationsUsersInfos,
            positionsSlotsUsersInfos: data.organization?.hideAssignmentsUntilConfirmationEmailSent
                ? vr.positionsSlotsUsersInfos.filter((psui) => psui.isConfirmationEmailSent)
                : vr.positionsSlotsUsersInfos
        }));
        return sortBy(
            filteredRegistrations,
            (vr) => -(vr.event.range?.start?.toMillis() ?? vr.event.startAt.toMillis())
        );
    }, [data.userInfo, data.organization]);

    return (
        loader || (
            <SpaceContainer
                isLoading={isLoading}
                organization={data.organization}
                spaceDesign={{
                    themeColors: data.organization?.membersSpaceDesign?.colors,
                    bannerSrc: data.organization?.membersSpaceDesign?.banner?.url,
                    logoSrc: data.organization?.membersSpaceDesign?.logo?.url
                }}
            >
                <MemberHeader isLoading={isLoading} userInfo={data.userInfo} />

                <Spacer height={{ '@initial': '7', '@tablet': '8', '@desktop': '11' }} />

                <Flex
                    direction={{ '@initial': 'column', '@desktop': 'row' }}
                    gap={{ '@initial': '9' }}
                >
                    <Flex
                        direction="column"
                        gap={{ '@initial': '9', '@desktop': '7' }}
                        css={{
                            width: '100%',
                            '@desktop': {
                                width: '700px'
                            }
                        }}
                    >
                        <Flex direction="column" gap="3">
                            <Box color="gray900" fontSize="textLg" fontWeight="medium">
                                {translate('votre_organizat_62762')}
                            </Box>

                            {isLoading ? (
                                <MemberAssignmentSkeleton />
                            ) : (
                                <UserInfoAssignments
                                    organization={data.organization}
                                    userInfo={data.userInfo}
                                />
                            )}
                        </Flex>

                        <Flex direction="column" gap="3">
                            <Box color="gray900" fontSize="textLg" fontWeight="medium">
                                {translate('_v_nements_auxq_19595')}
                            </Box>

                            {isLoading ? (
                                <MemberAssignmentSkeleton />
                            ) : (
                                <MemberAssignments registrations={registrations} />
                            )}
                        </Flex>
                    </Flex>

                    <Flex
                        direction="column"
                        gap="7"
                        css={{
                            width: '100%',
                            '@desktop': {
                                flex: '1'
                            }
                        }}
                    >
                        <Flex direction="column" gap="3">
                            <Flex align="center" gap="3">
                                <Box font="gray900 textLg medium" css={{ flex: '1' }}>
                                    {translate('vos_messages_14954')}
                                </Box>

                                {(data.userInfo?.campaigns.nodes ?? []).length > 0 && (
                                    <Box
                                        color="primary700"
                                        fontWeight="medium"
                                        css={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            history.push(
                                                MembersPaths.MEMBER_CAMPAIGNS(
                                                    organizationId,
                                                    userInfoId
                                                )
                                            );
                                        }}
                                    >
                                        {translate('voir_tout_01374')}
                                    </Box>
                                )}
                            </Flex>

                            {isLoading ? (
                                <MemberCampaignsSkeleton />
                            ) : (
                                <MemberCampaigns campaigns={data.userInfo.campaigns.nodes} />
                            )}
                        </Flex>

                        <Flex direction="column" gap="3">
                            <Flex align="center" gap="3">
                                <Box font="gray900 textLg medium" css={{ flex: '1' }}>
                                    {translate('vos_documents_03830')}
                                </Box>

                                {(data.userInfo?.documents.nodes ?? []).length > 0 && (
                                    <Box
                                        color="primary700"
                                        fontWeight="medium"
                                        css={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            history.push(
                                                MembersPaths.MEMBER_DOCUMENTS(
                                                    organizationId,
                                                    userInfoId
                                                )
                                            );
                                        }}
                                    >
                                        {translate('voir_tout_01374')}
                                    </Box>
                                )}
                            </Flex>

                            {isLoading ? (
                                <MemberDocumentsSkeleton />
                            ) : (
                                <MemberDocuments documents={data.userInfo.documents.nodes} />
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </SpaceContainer>
        )
    );
};
