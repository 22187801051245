import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { ProfilesQuery } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { MembersPaths } from 'common/src/util/membersPaths';
import * as React from 'react';
import { useProfilesQuery } from '../../../generated/graphqlHooks';

interface IUseProfilesListResult {
    isLoading: boolean;
    usersInfos: Emptyable<ProfilesQuery['user']['usersInfos']>;
}

export function useProfilesList(): IUseProfilesListResult {
    const {
        history,
        params: { organizationId }
    } = useHeavent();
    const { data, isLoading } = useProfilesQuery({ organizationId });

    React.useEffect(() => {
        if (data.user?.usersInfos.length === 1) {
            history.replace(MembersPaths.MEMBER(organizationId, data.user.usersInfos[0].id));
        }
    }, [data.user]);

    return {
        isLoading,
        usersInfos: data.user?.usersInfos
    };
}
