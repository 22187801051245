import { SupportedLanguage } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { fromString } from 'common/src/vo/supportedLanguage';
import { CurrentUser } from '../vo/user';

export function getLanguage(
    user: Emptyable<CurrentUser>,
    searchParams: URLSearchParams
): SupportedLanguage {
    if (searchParams.has('lang')) {
        return fromString(searchParams.get('lang')) || SupportedLanguage.En;
    } else if (user) {
        return user.language;
    } else {
        return fromString(navigator.language) || SupportedLanguage.En;
    }
}
